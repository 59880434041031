import styles from "./index.module.css";

const sizes = {
  medium: styles.medium
};

// basic modal has a z-index value of "100". Keep all modals between 100 and 200
const stackLevels = {
  "110": styles.stackLevel110,
  "120": styles.stackLevel120,
  "130": styles.stackLevel130,
};

// @todo Try to use "click" instead of "pointer down" for closing modal to give better user experience. Will need to address issue of signature modal being unintentionally closed by dragging mouse outside modal.

// Modal component is designed to shrink in on its content and has max-height, max-width settings in place
const Modal = ({
  children,
  flexColumn,
  onClose,
  showModal,
  size,
  stackLevel,
}) => {
  const backgroundClasses = [styles.background];
  if(stackLevel) {
    backgroundClasses.push(stackLevels[stackLevel]);
  }

  const modalClasses = [styles.modal];
  if(size) {
    modalClasses.push(sizes[size]);
  }
  if(flexColumn) {
    modalClasses.push(styles.flexColumn);
  }

  const handleClickBackground = e => {
    e.stopPropagation();
    onClose();
  };

  if(!showModal) {
    return null;
  }

  return (
    // using "onPointerDown" instead of "onClick" because dragging mouse outside modal triggered close action, which caused problems with the signature modal
    <div className={backgroundClasses.join(" ")} onPointerDown={handleClickBackground}>
      <div className={modalClasses.join(" ")} onPointerDown={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
