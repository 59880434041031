import { useRef, useState } from "react";
import SignatureBox from "components/SignatureBox";
import ActionsModal from "../Actions";

const SignatureModal = ({
  heading,
  onClose,
  onSubmit,
  showModal,
}) => {
  const canvasRef = useRef(null);

  const [hasSignature, setHasSignature] = useState(false);

  const handleSubmit = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      onSubmit(blob);
    });
  };

  return (
    <ActionsModal
      onClose={onClose}
      onSubmit={handleSubmit}
      showModal={showModal}
      submitDisabled={!hasSignature}
    >
      {heading && <h2 className="heading2">{heading}</h2>}
      <SignatureBox setHasSignature={setHasSignature} ref={canvasRef} />
    </ActionsModal>
  );
};

export default SignatureModal;
