import clsx from "clsx";
import Link from "next/link";
import styles from "./index.module.css";

const styleMap = {
  fill_dark: styles.fillBlue,
  fill_dark_small: styles.fillBlueSmall,
  fill_light: styles.fillOrange,
  fill_light_small: styles.fillOrangeSmall,
  ghost_dark: styles.ghostBlue,
  ghost_dark_small: styles.ghostBlueSmall,
  ghost_light: styles.ghostOrange,
  ghost_light_small: styles.ghostOrangeSmall,
  inline: styles.inline,
  plain: styles.plain,
};

const Button = ({
  children,
  danger,
  disabled = false,
  download,
  href,
  noWrap,
  onClick,
  openInNewWindow,
  style,
  type = "button",
}) => {
  const classes = clsx(
    styles.button,
    styleMap[style],
    {
      [styles.danger]: !!danger,
      [styles.noWrap]: !!noWrap,
    },
  );

  if(href) {
    const linkProps = {};
    if(download) {
      linkProps.download = download;
    }
    if(openInNewWindow) {
      linkProps.target = "_blank";
      linkProps.rel = "noreferrer";
    }

    return (
      <Link
        className={clsx(styles.linkReset, classes)}
        href={href}
        {...linkProps}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      className={clsx(styles.buttonReset, classes)}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
