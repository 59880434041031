import {
  forwardRef,
  useLayoutEffect,
  useRef,
} from "react";
import Button from "components/Button2";
import styles from "./index.module.css";

const drawColor = "black";
// lines and dots should be comparable in size
const lineWidth = 2;
const dotRadius = 1.5;

const SignatureBox = ({ setHasSignature }, canvasRef) => {
  const contextRef = useRef(null);
  const isDrawing = useRef(false);
  const isDrawingLine = useRef(false);

  const enableDrawing = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const context = contextRef.current;
    context.beginPath();
    context.moveTo(offsetX, offsetY);
    isDrawing.current = true;
  };

  const draw = (e) => {
    if(!isDrawing.current) {
      return;
    }
    if(!isDrawingLine.current) {
      isDrawingLine.current = true;
    }
    const { offsetX, offsetY } = e.nativeEvent;
    const context = contextRef.current;
    context.lineTo(offsetX, offsetY);
    context.stroke();
  };

  const disableDrawing = () => {
    isDrawing.current = false;
    isDrawingLine.current = false;
    // enable submit as soon as first mark is drawn
    setHasSignature(true);
  };

  const handlePointerUp = (e) => {
    // if pointer is lifted before any movement occurs, then draw a dot
    if(isDrawing.current && !isDrawingLine.current) {
      const { offsetX, offsetY } = e.nativeEvent;
      const context = contextRef.current;
      context.beginPath();
      context.arc(offsetX, offsetY, dotRadius, 0, 2 * Math.PI);
      context.fill();
    }
    disableDrawing();
  };

  const handlePointerLeave = (e) => {
    if(isDrawing.current) {
      // need a "draw" here for rapid exit from canvas area (to ensure line is drawn to canvas edge)
      draw(e);
      disableDrawing();
    }
  };

  const clearCanvas = () => {
    contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setHasSignature(false);
  };

  useLayoutEffect(() => {
    const setUpCanvas = () => {
      const canvas = canvasRef.current;

      // Set canvas size based on wrapper size:
      // get dimensions of canvas following css styling and then apply to canvas element
      // this ensures that the drawing surface matches to the element size
      // this allows us to size the canvas just by sizing its wrapper
      const { width, height } = canvas.getBoundingClientRect();

      // Adjust pixel density for high resolution screens:
      // we account for device pixel density in order to de-blur image on high resolution screens
      // this "scale" factor is used in the width/height of canvas and in scaling the context
      const scale = window.devicePixelRatio;

      // setting width or height clears the canvas (in case drawing exists already)
      canvas.width = Math.floor(width * scale);
      canvas.height = Math.floor(height * scale);

      // if re-setting up existing canvas, clear "has signature" state
      setHasSignature(false);

      const context = canvas.getContext("2d");
      context.scale(scale, scale);
      context.strokeStyle = drawColor;
      context.fillStyle = drawColor;
      context.lineCap = "round";
      context.lineJoin = "round";
      context.lineWidth = lineWidth;
      contextRef.current = context;
    };

    // using a resize observer on the canvas element for 2 reasons:
    // - when size of element changes (likely due to screen size change), canvas must be set up again
    // - for unknown reason, initial canvas setup occurs with canvas slightly smaller than its ultimate size, and so drawing ends up out of sync with pointer location
    const canvasResizeObserver = new ResizeObserver(() => {
      if(canvasRef.current) {
        setUpCanvas();
      }
    });
    canvasResizeObserver.observe(canvasRef.current);
    setUpCanvas();

    return () => canvasResizeObserver.unobserve(canvasRef.current);
  }, []);

  return (
    <div>
      <div className={styles.buttons}>
        <Button onClick={clearCanvas} style="ghost_dark_small" danger>Clear</Button>
      </div>
      <div className={styles.canvasWrapper}>
        <canvas
          className={styles.canvas}
          ref={canvasRef}
          onPointerDown={enableDrawing}
          onPointerMove={draw}
          onPointerUp={handlePointerUp}
          onPointerLeave={handlePointerLeave}
        />
      </div>
    </div>
  );
};

// using forwardRef here instead of in component definition to avoid linting error regarding "display name"
export default forwardRef(SignatureBox);
