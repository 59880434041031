import { useState } from "react";
import { v4 as uuid } from "uuid";
import Button from "components/Button2";
import SignatureModal from "components/Modal/Signature";
import { useAlert } from "contexts/alert";
import { useConfirm } from "contexts/confirm";
import { useLoader } from "contexts/loader";
import { post } from "lib/api";
import { CATEGORY_TYPES } from "lib/consts";
import { uploadToBucket } from "lib/upload";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import styles from "./styles.module.css";

// @todo instead of Loader, can disable submit button during upload (the way File component works)

const SignatureInput = ({
  field = {},
  indicateUnanswered,
  onChange,
  value, // upload record ID
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setAlert } = useAlert();
  const { setConfirm } = useConfirm();
  const { hideLoader, showLoader } = useLoader();

  const { label, modalHeading, required } = field;

  const handleUploadSignature = async (blob) => {
    showLoader();
    const storageFileName = `signature-${new Date().valueOf()}-${uuid()}.png`;
    const { success } = await uploadToBucket(blob, storageFileName);
    if(success) {
      const { data: upload } = await post("/api/uploads", { storageFileName });
      onChange(field, upload._id);
      setIsModalOpen(false);
    } else {
      setAlert("Signature upload failed");
    }
    hideLoader();
  };

  const handleDeleteSignature = () => {
    setConfirm({
      prompt: "Delete this signature?",
      onConfirm: () => {
        onChange(field, null);
      }
    });
  };

  const isUnanswered = getIsInputUnanswered(value);

  return (
    <div className={styles.root}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      {value && <img className={styles.image} src={CATEGORY_TYPES.FILE_BY_UPLOAD.getApiPath(value)} alt="Signature" />}
      <div>
        {value
          ? <Button onClick={handleDeleteSignature} style="ghost_dark_small" danger>Delete</Button>
          : <Button onClick={() => setIsModalOpen(true)} style="fill_dark">Sign</Button>
        }
      </div>
      <SignatureModal
        heading={modalHeading}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleUploadSignature}
        showModal={isModalOpen}
      />
    </div>
  );
};

export default SignatureInput;
